<template>
  <div class="wrapper" v-click-outside="textareaBlur">
    <FloatLabel :label="label" :optional="optional">
      <div :class="[`${error ? 'invalid' : ''}`]" class="text-area-wrapper">
        <b-form-textarea
          id="textarea"
          class="shadow-none form-control text-area-custom custom-scrollbar"
          :name="name"
          no-resize
          spellcheck="false"
          :placeholder="!onFocus ? placeholder : ''"
          v-model="inputValue"
          @input="trimInputValue"
          :rows="rows"
          :class="[`${error ? 'invalid' : ''}`]"
          autocomplete="off"
          style="height: auto !important"
          @focus="textareaFocus"
          @blur="textareaBlur"
          ref="carDescription"
        ></b-form-textarea>
      </div>
    </FloatLabel>
    <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
      {{ error ? helperText : "" }}
    </div>
  </div>
</template>
  <script>
export default {
  components: {
    FloatLabel: () => import("./FloatLabel.vue"),
  },
  name: "CustomTextArea",
  inheritAttrs: false,
  model: {
    prop: "description",
    event: "change",
  },
  data() {
    return {
      inputValue: this.description,
      onFocus: false,
    };
  },
  props: {
    description: {
      type: [Number, String],
      default: () => "",
    },
    label: String,
    name: {
      type: String,
      default: () => "",
    },
    rows: {
      type: String,
      default: () => "5",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    error: Boolean,
    helperText: String,
    isFocus: Boolean,
    optional: Boolean,
  },
  methods: {
    textareaFocus() {
      this.onFocus = true;
    },
    textareaBlur() {
      this.onFocus = false;
      this.emitInputValue();
    },
    emitInputValue() {
      if (this.inputValue !== this.initTextPlaceholder) {
        this.$emit("change", this.inputValue);
      }
    },
    trimInputValue() {
      const cursorPos = this.$refs.carDescription.selectionStart;

      this.inputValue = this.inputValue.replace(/^\s+|\s+$/g, '');

      // Restore the cursor position
      this.$nextTick(() => {
        this.$refs.carDescription.setSelectionRange(cursorPos, cursorPos);
      });
    }
  },
};
</script>
  
  <style lang="scss">
$gray-color: #ced4da;
select.form-control.invalid,
input.form-control.invalid {
  border-color: red !important ;
}
.invalid {
  border-color: red !important;
}
.err-text {
  color: red;
  font-style: italic;
  min-height: 16px;
}
.text-area-wrapper {
  border: 1px solid $gray-color;
  border-radius: 8px;
  position: relative;

  .text-area-custom::placeholder {
    color: #989898 !important;
    font-weight: 600 !important;
  }

  .form-control {
    border: none !important;
    padding: 0.575rem 0.75rem;
    &.blank {
      color: #989898 !important;
    }
  }
}
.wrapper {
  position: relative;
}
</style>